// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"orodha@4.331.0"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import { getClientsideEnvironmentId } from '@sbt-web/utils';
import { isUnsupportedBrowser } from '@sbt-web/browserlist-regexp';

if (process.env.NODE_ENV === 'production' && !isUnsupportedBrowser()) {
  import('@sentry/nextjs').then((Sentry) => {
    Sentry.init({
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      environment: process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT,
      //debug: true,
      // Track 40% of errors and 1% of traces
      sampleRate: 0.4,
      tracesSampleRate: 0.01,
      //Replay config here: https://docs.sentry.io/platforms/javascript/guides/nextjs/session-replay/
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0.4,
      integrations: [
        //https://docs.sentry.io/platforms/javascript/configuration/integrations/httpclient/
        Sentry.httpClientIntegration(),
        Sentry.browserTracingIntegration({
          beforeStartSpan: (context) => {
            const locationPath = window.location.pathname;
            let spanName = locationPath;
            try {
              const pageType = locationPath.startsWith('/annunci')
                ? '/listing'
                : '/addetail';
              spanName = pageType;
              if (pageType === '/listing') {
                // when the name is not /listing, means that we are navigating clientside
                if (context.name !== '/listing') {
                  spanName = `${pageType}-client-nav`;
                }
              }
            } catch (e) {
              console.error(`Sentry error tracing: ${e}`);
            }

            return {
              ...context,
              name: spanName,
            };
          },
        }),
        // Keep the Replay integration as before. Disabled due to performance issues
        /*     Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        //FIXME: This is a temporary fix to mask the username address
        mask: ['#index-module_fluid-container__3n68n, header'],
      }),*/
        Sentry.browserApiErrorsIntegration({
          XMLHttpRequest: true,
          eventTarget: false,
          requestAnimationFrame: false,
          setInterval: false,
          setTimeout: false,
        }),
      ],
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        // Avast extension error
        '_avast_submit',
        // This is supported in all our supported browsers:
        // https://caniuse.com/mdn-api_crypto_randomuuid
        'crypto.randomUUID',

        //Subito's ADV
        "Cannot read properties of undefined (reading 'reclame-listing-top')",
        "Cannot read property 'reclame-listing-top' of undefined",
        "undefined is not an object (evaluating 'e[o]')",
        "undefined is not an object (evaluating 'delete window.PWT.bidMap[e]')",
        "undefined is not an object (evaluating 'window.PWT.bidMap[e]')",
        'google.ads.search.Ads: container "afscontainer1" does not exist.',
        'Pn(...).getGlobalConfigValue is not a function',
        'NS_ERROR_NOT_INITIALIZED',
        "Cannot read properties of undefined (reading 'apn_top_tag')",
        'window.PWT.bidMap is undefined',
        "Cannot read properties of undefined (reading 'apn_aside')",
        "Cannot read properties of undefined (reading 'gpt-msite-adview-1')",
        "a.callbacks.forEach is not a function. (In 'a.callbacks.forEach(n.triggerPixel)', 'a.callbacks.forEach' is undefined)",

        // Benign error
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',

        /google_tag_manager/g,

        //Previously added:
        // Ignore the DMP error because it only occurs in cached code
        // which has been updated. We can't action it.
        /dmp adapter currently not supported/gi,
        // Promise.allSettled is supported on all our supported browsers.
        // If this error occurs, we won't action it.
        /Promise.allSettled is not a function/gi,
        //https://subitoit.sentry.io/issues/2262418874/?project=207943&query=is%3Aunresolved&referrer=issue-stream&sort=freq&statsPeriod=24h&stream_index=12
        //ADV Video:
        /AbortError: The play\(\) request was interrupted by a call to pause\(\)/gi,
        //Ignore all fetch errors from external scripts
        'Rejected',
        'Failed to fetch',
        /NetworkError when attempting to fetch resource/,
        /Load failed/,
        //Ignore Lira error related to the fact that the element is not present in the DOM
        /Error: A DOM element with ID/,
        //Pulse
        /pulse-sdk/gi,
        //unhandled errors: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/14
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        //Adv chunk:
        /Loading chunk/gi,
        /Loading CSS chunk/gi,
        /ChunkLoadError/gi,
        //Syntax error (legacy browser):
        "Unexpected token 'else'",
        'Unexpected end of input',
        'Unexpected identifier',
        "Unexpected token '<'",
        //Network errors:
        'Request aborted',
        'Request failed',
        'Network Error',
        'Load failed',
      ],
      denyUrls: [
        //https://sentry.io/organizations/subitoit/issues/3126363670/?project=6273204&query=&sort=user&statsPeriod=14d
        /peboki\.wukedowoki\.com/,
        //https://sentry.io/organizations/subitoit/issues/3127453589/?project=6273204&query=&sort=user&statsPeriod=14d
        /data1\.siwathe\.com/,
        // Start ADV
        /pagead\/js/i,
        /a\.teads\.tv/i,
        /AdServer\/js\/pwt/i,
        /ads\.pubmatic\.com/,
        // End ADV
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        //Prevent error from external scripts:
        /www\.googletagmanager\.com\/gtm\.js/i,
        /gtag\/js/i,
        /richmedia\/studio\/mu\/templates\/hifi\/hifi\.js/i,
        /widgets\.outbrain\.com/i,
        /cdn\.doubleverify\.com\/dv-measurements/i,
        /ghisa\.subito\.it/i,
        /h\.online-metrix\.net/i,
        // Uses a 'fire.js' that fails with an unhandled exception
        's.cpx.to',
        /s\.pinimg\.com/i,
        /p\.cpx\.to/i,
        /c\.blendee\.com/i,
        /omg\.js/i,
        /map\.yatmo\.com/i,
      ],
    });

    const environmentId = getClientsideEnvironmentId();

    if (environmentId !== null) {
      Sentry.setUser({ id: environmentId });
    }
  });
}
